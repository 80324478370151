import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MapContainer, SVGMapContainer, TitleContainer, IndicatorsContainer, ReturnContainer, NavbarContainer, MapGridContainer, LegendContainer, MoreDownContainer, FooterContainer } from './styled'
import Navbar from 'components/organisms/navbar/main_desktop'
import SVGMap from 'components/organisms/svg_map/mexico/index'
import WelcomeSVG from 'components/atoms/images/mexico_title'
import IndicatorsSVG from 'components/atoms/images/mexico_indicators_map'
import ReturnImage from 'components/atoms/images/return_button'
import ReturnButton from 'components/atoms/buttons/icon'
import Lengend from 'components/molecules/map_legend/mexico'
import MoreDown from 'components/atoms/images/see_more'
import MexicoContent from 'components/molecules/mexico_info'
import Footer from 'components/molecules/footer'
import MexMapData from 'assets/map/mex_data'
import { NAVBAR_TYPE } from 'constants/index'
import * as routes from 'constants/routes'

class MexicoDesktopView extends Component {
  constructor(props) {
    super(props)
    this.onReturnClick = this.onReturnClick.bind(this)
  }

  onReturnClick() {
    this.props.history.push(routes.MAP)
  }

  render() {
    return (
      <>
        <NavbarContainer className='bg-'>
          <Navbar type={NAVBAR_TYPE.zones} />
        </NavbarContainer>
        <MapContainer className='d-flex justify-content-center'>
          <MapGridContainer className='bg-'>
            <ReturnContainer>
              <ReturnButton imageComponent={<ReturnImage />} onClick={this.onReturnClick} type='button' />
            </ReturnContainer>
            <SVGMapContainer className='bg-'>
              <SVGMap formattedGeoData={this.props.zones_svg_json} initialGeoData={MexMapData} />
            </SVGMapContainer>
            <TitleContainer className='bg-'>
              <WelcomeSVG />
            </TitleContainer>
            <IndicatorsContainer className='bg-'>
              <IndicatorsSVG />
            </IndicatorsContainer>
          </MapGridContainer>
        </MapContainer>
        <LegendContainer className='bg-'>
          <Lengend />
        </LegendContainer>
        <MoreDownContainer className='d-flex justify-content-center align-items-center'>
          <MoreDown />
        </MoreDownContainer>
        <MexicoContent allowed={this.props.mexico_data.allowed} />
        <FooterContainer className='bg-'>
          <Footer />
        </FooterContainer>
      </>
    )
  }
}

function mapStateToProps({ map }) {
  const { zones_svg_json, mexico_data } = map

  return { zones_svg_json, mexico_data }
}

MexicoDesktopView = connect(mapStateToProps, {})(MexicoDesktopView)

export default withRouter(MexicoDesktopView)
