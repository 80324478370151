import { firestore } from 'firebase/index'
import { MAP, SHARED, FIRESTORE, ALERTS, COUNTRIES_WITH_ZONES } from 'constants/index'
import map from 'lodash/map'
import LatinMapData from 'assets/map/latin_data'
import MexMapData from 'assets/map/mex_data'
import { getAbortionLevelOfCountries, getSearchOptions, getAbortionLevelOfZones } from 'helpers/index'

export function fetchZoneByCode(country_code, zone_code, callback = () => {}) {
  let countriesArray = []
  let country = {}
  let zonesArray = []
  let zone = {}
  let query

  return (dispatch) => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })
    query = firestore.collection(FIRESTORE.COUNTRIES_SIMPLE).where('code', '==', country_code)
    query
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let country_id = { id: doc.id }
          let data = doc.data()
          countriesArray.push({ ...country_id, ...data })
        })

        country = countriesArray[0]

        // console.log('data', {
        // 	COUNTRIES: FIRESTORE.COUNTRIES,
        // 	id: country.id,
        // 	zones: FIRESTORE.COUNTRY_ZONES,
        // 	code: zone_code
        // })

        query = firestore.collection(FIRESTORE.COUNTRIES).doc(country.id).collection(FIRESTORE.COUNTRY_ZONES).where('code', '==', zone_code)
        query
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let zone_id = { id: doc.id }
              let data = doc.data()
              zonesArray.push({ ...zone_id, ...data })
            })

            zone = zonesArray[0]
            dispatch({
              type: MAP.FETCH_ZONE_SUCCESS,
              country,
              zone
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            callback()
          })
          .catch((error) => {
            console.log('Error en el detalle de la zona del país', error)
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
              type: ALERTS.SHOW_ERROR_MESSAGE,
              payload: 'No existe información.'
            })
          })
      })
      .catch((error) => {
        console.log('Error en el detalle de la zona país', error)
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
        dispatch({
          type: ALERTS.SHOW_ERROR_MESSAGE,
          payload: 'No existe información.'
        })
      })
  }
}

export function fetchCountryByCode(country_code, callback = () => {}) {
  let countriesArray = []
  let country = {}
  let query
  return (dispatch) => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })
    query = firestore.collection(FIRESTORE.COUNTRIES).where('code', '==', country_code)
    query
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let country_id = { id: doc.id }
          let data = doc.data()
          countriesArray.push({ ...country_id, ...data })
        })
        country = countriesArray[0]

        dispatch({
          type: MAP.FETCH_COUNTRY_SUCCESS,
          country
        })
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
        callback()
      })
      .catch((error) => {
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
        dispatch({
          type: ALERTS.SHOW_ERROR_MESSAGE,
          payload: 'No existe información.'
        })
      })
  }
}

export function fetchAllCountries() {
  let countriesArray = []
  let query
  return (dispatch) => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })
    query = firestore.collection(FIRESTORE.COUNTRIES_SIMPLE)
    query
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let country_id = { id: doc.id }
          let data = doc.data()
          countriesArray.push({ ...country_id, ...data })
        })
        // Con la data obtenida de Firestore, se mapea con la data del SVG para agregarle el nivel de causal
        let countriesGeoJsonData = getAbortionLevelOfCountries(LatinMapData, countriesArray)
        dispatch({
          type: MAP.FETCH_COUNTRIES_SUCCESS,
          countries: countriesGeoJsonData
        })
        // Y se crean las opciones para el buscador de países
        let searchOptions = getSearchOptions(countriesArray)
        dispatch({
          type: MAP.GET_COUNTRIES_SEARCH_OPTIONS,
          payload: searchOptions
        })
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
      })
      .catch((error) => {
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
        dispatch({
          type: ALERTS.SHOW_ERROR_MESSAGE,
          payload: 'No existe información.'
        })
      })
  }
}

export function fetchAllCountriesOptions() {
  let countriesArray = []
  let query
  return (dispatch) => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })
    query = firestore.collection(FIRESTORE.COUNTRIES_SIMPLE)
    query
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let country_id = { id: doc.id }
          let data = doc.data()
          countriesArray.push({ ...country_id, ...data })
        })

        let searchOptions = getSearchOptions(countriesArray)
        dispatch({
          type: MAP.GET_COUNTRIES_SEARCH_OPTIONS,
          payload: searchOptions
        })
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
      })
      .catch((error) => {
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
        dispatch({
          type: ALERTS.SHOW_ERROR_MESSAGE,
          payload: 'No existe información.'
        })
      })
  }
}

firestore.collection(FIRESTORE.COUNTRIES).constructor.prototype.getMultipleZonesOf = (countries_with_zones, dispatch) => {
  return Promise.all(
    map(countries_with_zones, (country) => {
      const zonesArray = []
      return firestore
        .collection(FIRESTORE.COUNTRIES)
        .doc(country)
        .collection(FIRESTORE.COUNTRY_ZONES)
        .get()
        .then((zones) => {
          zones.forEach((doc) => {
            let zone_id = { id: doc.id }
            let data = doc.data()
            zonesArray.push({ ...zone_id, ...data })
          })
          return zonesArray
        })
        .catch((error) => {
          dispatch({ type: SHARED.PAGE_STOP_LOADING })
          dispatch({
            type: ALERTS.SHOW_ERROR_MESSAGE,
            payload: 'No existe información.'
          })
        })
    })
  )
}

export function fetchZonesOfCountries(countries_with_zones, callback) {
  return (dispatch) => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })
    firestore
      .collection(FIRESTORE.COUNTRIES)
      .getMultipleZonesOf(countries_with_zones, dispatch)
      .then((array_of_countries_with_zones) => {
        let arrayZonesOfCountries = []
        array_of_countries_with_zones.forEach((country_with_zones) => {
          arrayZonesOfCountries = arrayZonesOfCountries.concat(country_with_zones)
        })
        dispatch({
          type: MAP.FETCH_ZONES_OF_COUNTRIES_SUCCESS,
          zones_of_countries: arrayZonesOfCountries
        })
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
        callback(arrayZonesOfCountries)
      })
      .catch((error) => {
        dispatch({ type: SHARED.PAGE_STOP_LOADING })
        dispatch({
          type: ALERTS.SHOW_ERROR_MESSAGE,
          payload: 'No existe información.'
        })
      })
  }
}

export function fetchAllZonesOfMexico() {
  let zonesArray = []
  let mexicoData = {}

  return (dispatch) => {
    dispatch({ type: SHARED.PAGE_IS_LOADING })
    firestore
      .collection(FIRESTORE.COUNTRIES)
      .doc(COUNTRIES_WITH_ZONES.MXN)
      .get()
      .then((querySnapshotAllowed) => {
        mexicoData = querySnapshotAllowed.data()
        // console.log('mexicoData-->', mexicoData)

        firestore
          .collection(FIRESTORE.COUNTRIES_SIMPLE)
          .doc(COUNTRIES_WITH_ZONES.MXN)
          .collection(FIRESTORE.COUNTRY_ZONES_SIMPLE)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let zone_id = { id: doc.id }
              let data = doc.data()
              zonesArray.push({ ...zone_id, ...data })
            })
            // Con la data obtenida de Firestore, se mapea con la data del SVG para agregarle el nivel de causal
            let zonesGeoJsonData = getAbortionLevelOfZones(MexMapData, zonesArray)
            // console.log('zonesGeoJsonData-->', zonesGeoJsonData)

            dispatch({
              type: MAP.FETCH_ZONES_SUCCESS,
              zones: zonesGeoJsonData,
              mexico_data: mexicoData
            })
            // Y se crean las opciones para el buscador de países
            let searchOptions = getSearchOptions(zonesArray)
            dispatch({
              type: MAP.GET_ZONES_SEARCH_OPTIONS,
              payload: searchOptions
            })
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
          })
          .catch((error) => {
            dispatch({ type: SHARED.PAGE_STOP_LOADING })
            dispatch({
              type: ALERTS.SHOW_ERROR_MESSAGE,
              payload: 'No existe información.'
            })
          })
      })
  }
}

export function cleanCurrentData() {
  return (dispatch) => {
    dispatch({ type: MAP.CLEAN_CURRENT_DATA })
  }
}
