import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'

let config = {
  apiKey: process.env.REACT_APP_PRD_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PRD_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PRD_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PRD_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PRD_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PRD_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_PRD_FIREBASE_MEASUREMENT_ID
}

if (!firebase.apps.length) firebase.initializeApp(config)

const auth = firebase.auth()
const storage = firebase.storage()
const firestore = firebase.firestore()
const firestoreFieldValue = firebase.firestore.FieldValue
const FacebookAuthProvider = new firebase.auth.FacebookAuthProvider()

export { auth, storage, firestore, firestoreFieldValue, FacebookAuthProvider }
